@import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css?family=Maven+Pro&display=swap');

$initialState: translateY(0); 

*{
  font-family: 'Maven Pro', sans-serif;
}

html{
  scroll-behavior: smooth;
}

.wrapper{
  max-width: 100vw;
}

.bucketlist{
    display: none; 
  }

  .container p {
    font-family: 'Indie Flower', cursive;
    font-size: 1.2rem; 
  }

  @media only screen and (max-width: 500px) {
    body{
      text-align: center; 
    }
    h5{
      padding-bottom: 50px; 
    }
      .season-img{
      background-size: auto;
      max-width: 400px;
    }
    .mobile-hide{
      display: none; 
    }
    .profile-picture{
      width: 100vw;
    }

    .header-img {
      padding: 0; 
  }

  .wrapper {
    padding-top: 10px;
}

  }

  .example_f {
    background-color: #e9e9e9;
    border-radius: 4px;
    border: none;
    color: #000000bd;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    padding: 11px;
    width: 170px;
    transition: all 0.4s;
    cursor: pointer;
    margin: 5px;
    margin-bottom: 50px; 
    
  }

  .example_f span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
  }
  .example_f span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  .example_f:hover span {
    padding-right: 25px;
  }
  .example_f:hover span:after {
    opacity: 1;
    right: 0;
  }


  #autumn{
    button:hover{
      background: linear-gradient(to right, #eb994c, #fdff98) !important;
    }
  }

  #winter{
    button:hover{
      background: linear-gradient(to right, #67faff, #e6fffe) !important;
    }
  }

  #spring{
    button:hover{
      background: linear-gradient(to right, #88ffb0, #fbfd8b) !important;
    }
  }

  #summer{
    button:hover{
      background: linear-gradient(to right, #34dd5e, #cbffb7) !important;
    }
  }
  
  .button_cont{
    display: inline-block;
  }

  .solutionLink:hover{
    text-shadow: 1px 1px 2px #fbfd8b;
    cursor: pointer;
}

.hidden {
  display:none;
}

h2{
  padding: 15px; 
}


  /* Tablet Styles */
  @media only screen and (min-width: 401px) and (max-width: 960px) {
    body{
      text-align: center; 
    }
    .mobile-hide{
      display: none; 
    }
    .profile-picture{
      width: 60vw;
    }   
    .header-img{
      padding-bottom: 10px;
    }
    h5{
      padding-bottom: 50px; 
    }
    p{
      padding-bottom: 0; 
    }
    .padtest{
      margin-top: 50px; 
        }
  }

  
  @media only screen and (min-width: 501px) and (max-width: 960px) {
  #Awesome{
    position: absolute;
    right: 0; 
  }
}



h5{
  font-family: 'Indie Flower', cursive;
}



@media only screen and (min-width: 961px) {
  .main-section{
    display: flex; 
    justify-content: space-evenly; 
    position: relative;
    min-height: 150px; 
  }
  
  
  .row {
    display: flex; /* equal height of the children */
  }
  
  .col {
    flex: 1; /* additionally, equal width */
  }

  .profile-picture{
    width: 20vw; 
    height: auto;
  }

  .season-img{
    width: 500px; 
    height: auto;
    background-size: cover; 
  }

  #Awesome{
    position: absolute;
    right: 15%;
  }

  
}

.nav-fadein{
  opacity: 0;
  transform: translateY(50px); 
  transition: all 1s ease-in;
}

.nav-visible{
opacity: 1;
transform: translateY(0); 
}

.main-fadein{
  opacity: 0;
  transition: all 1s ease-in;
}

.main-visible{
opacity: 1; 
}


#Awesome .circle_wrapper{
  overflow: initial;
}